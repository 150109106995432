import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAtuh";

const PrivateRoute = ({ children }) => {
    const isLoggedIn = useAuth();

    return isLoggedIn ? children : <Navigate to="/login" />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
    children: PropTypes.node,
};

// 1029426823199 - rt7i77965uevg79gu1ss7gmscqtuvrk1.apps.googleusercontent.com;
